import React from "react";
import { connect } from "react-redux";
import { defaultLanguage } from "../../../helper/utility";
import { language } from "../../../languageProvider/language/language";
import ReadMore from "../../common/ReadMore";

import GalleryModal from "./components/Gallery.modal";
import ListModal from "./components/List.modal";
import TitleModal from "./components/Title.modal";

const HatChefBody = ({ modalRedux }) => {
  const {
    otherProps: {
      markerInfo: { title, data }
    }
  } = modalRedux;

  return (
    <div className="row h-100">
      <div
        className="col-12 col-sm-6 h-lg-100"
        style={{ position: "relative" }}
      >
        <GalleryModal minImg={false} gallery={data.img} />
      </div>

      <div className="col-12 col-sm-6">
        <div
          className="overflow-lg-scroll px-15 mobilelandscape bodyContentSection"
          style={{ height: "calc(80vh - 210px)", overflowY: "auto" }}
        >
          <div className="row">
            <div className="col-12 pt-3">
              <TitleModal title={title} />
            </div>
            <div className="col-12" style={{ marginTop: "30px" }}>
              <h6
                className="mb-3"
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px"
                }}
              >
                {language[defaultLanguage].ingredienti}
              </h6>
              <ListModal list={data.ingredients} />
            </div>
          </div>

          <div className="row">
            <div className="h-100 col-12" style={{ marginTop: "30px" }}>
              <h6
                className="mb-3"
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px"
                }}
              >
                {language[defaultLanguage].procedimento}
              </h6>
              <ReadMore limit={9999}>{data.recipe}</ReadMore>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};
export default connect(mapStateToProps)(HatChefBody);
